<div class="finish-page" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="finish-card">
        <h3 class="mat-title">Set new password</h3>
        <p *ngIf="success" class="msg success">Password successfully changed!</p>

        <form *ngIf="!success" class="finish-form" (ngSubmit)="onSubmit()" [formGroup]="finishForm"> 
    
            <p *ngIf="errorMessage" class="msg mat-error">{{errorMessage}}</p>
            <mat-form-field class="finish-input"> 
                <input matInput type="password"
                        id="newpassword"  
                        formControlName="newPassword"
                        placeholder="New password"
                        value=""
                        data-test="newPassTxt">
                <mat-error *ngIf="finishForm.hasError('required', ['newPassword'])">Required</mat-error>
                <mat-error *ngIf="finishForm.hasError('minlength', ['newPassword'])">Password should be at least 4 characters long</mat-error>
            </mat-form-field>

            <mat-form-field class="finish-input"> 
                <input matInput type="password"
                        id="confirmpassword"  
                        formControlName="confirmPassword"
                        placeholder="Confirm password"
                        value=""
                        data-test="confirmPassTxt">
                <mat-error *ngIf="finishForm.hasError('required', ['confirmPassword'])">Required</mat-error>
                <mat-error *ngIf="finishForm.hasError('minlength', ['confirmPassword'])">Password should be at least 4 characters long</mat-error>
                <mat-error *ngIf="finishForm.hasError('matchPassword', ['confirmPassword'])">Password not match</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" data-test="finishResetBtn">Change password</button>

        </form>

        <a routerLink="/login" mat-button data-test="backBtn">Back to login</a>

    </mat-card>
</div>
