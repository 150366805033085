import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { GetOAuthToken, SetOAuthToken } from '@api/interactors';
import { Authorities } from '@core/models/user/authorities';
import { User } from '@core/models/user/user';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {
  user = new BehaviorSubject<User>(undefined);

  constructor(
    private setOAuthToken: SetOAuthToken,
    private getOAuthToken: GetOAuthToken
  ) {
    this.setCurrentUser();
  }

  login(oauth) {
    this.setOAuthToken.execute(oauth);
    this.setCurrentUser();
  }

  logout() {
    this.setOAuthToken.execute(undefined);
    this.user.next(undefined);
  }

  hasAuthorities(authorities: Authorities[]) {
    const currentUserAuthorites = this.user.getValue().authorities;

    return authorities.some(authority => currentUserAuthorites.includes(authority));
  }

  hasDefaultCoop() {
    return this.user.getValue().defaultCoop !== undefined;
  }

  getDefaultCoop() {
    return this.user.getValue().defaultCoop;
  }

  isUserAuthenticated(): boolean {
    if (!this.getOAuthToken.execute()) {
      return false;
    }
    const refreshToken = this.getOAuthToken.execute().refresh_token;

    return decode(refreshToken).exp > Math.floor(Date.now() / 1000);
  }

  private setCurrentUser() {
    const oauth = this.getOAuthToken.execute();
    if (oauth) {
      const accessToken = decode(oauth.access_token);
      this.user.next({
        email: accessToken.email,
        firstName: accessToken.first_name,
        lastName: accessToken.last_name,
        authorities: accessToken.authorities,
        defaultCoop: accessToken.default_coop
      });
    }
  }
}
