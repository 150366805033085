import { Observable } from 'rxjs';
import { UseCase } from '../interactors/UseCase';
import { Resource } from '../models';
import { Http } from '../models/http';
import { LocationStringMap } from '../util';
import { RequestResource } from './RequestResource';

export namespace GetResource {
  export interface Params<L extends string> {
    parent: Resource<L>;
    rel: L;
    search?: LocationStringMap;
    noLinkError?: boolean;
  }
}

export class GetResource<L extends string, T>
    implements UseCase<GetResource.Params<L>, Observable<T|undefined>> {
  private requestResource: RequestResource<L, void, T>;

  constructor(private http: Http) {
    this.requestResource = new RequestResource(http);
  }

  execute(params: GetResource.Params<L>): Observable<T|undefined> {
    return this.requestResource.execute({
      method: 'GET',
      parent: params.parent,
      rel: params.rel,
      search: params.search,
      noLinkError: params.noLinkError
    });
  }
}
