import { Injectable } from '@angular/core';
import { OAuthToken } from '../../models';
import { StorageGet } from '../storage';
import { UseCase } from '../UseCase';

@Injectable()
export class GetOAuthToken implements UseCase<void, OAuthToken|undefined> {
  private storageGet = new StorageGet<string>();

  execute(): OAuthToken|undefined {
    const accessToken = this.storageGet.execute('oauth.access_token');
    const tokenType = this.storageGet.execute('oauth.token_type');
    const refreshToken = this.storageGet.execute('oauth.refresh_token');
    const expiresIn = this.storageGet.execute('oauth.expires_in');
    const scope = this.storageGet.execute('oauth.scope');
    const jti = this.storageGet.execute('oauth.jti');

    if (accessToken && tokenType && refreshToken && expiresIn && scope && jti) {
      return <OAuthToken>{
        access_token: accessToken,
        token_type: tokenType,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        scope,
        jti
      };
    } else {
      return undefined;
    }
  }
}
