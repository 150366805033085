import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccessToaster(msg: string, action?: string): Observable<void> {
    return this.snackBar.open(msg, action, {
        duration: 3000,
        horizontalPosition: 'center'
    }).onAction();
  }

  showErrorToaster(msg: string, action?: string): Observable<void> {
    return this.snackBar.open(msg, action, {
        panelClass: 'error-toaster',
        duration: 10000,
        horizontalPosition: 'center'
    }).onAction();
  }

  showWarningToaster(msg: string, action?: string): Observable<void> {
    return this.snackBar.open(msg, action, {
        panelClass: 'warning-toaster',
        duration: 10000,
        horizontalPosition: 'center'
    }).onAction();
  }
}
