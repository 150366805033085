<div class="login-page" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="login-card" >
        <div class="logo"><img src="/assets/logo.png" alt="Blimling and Associates"></div>
        <h3 class="mat-title">Sign-In to Forward Contracting System</h3>

        <form class="login-form" (ngSubmit)="onSubmit()" [formGroup]="loginForm"> 
            <p *ngIf="errorMessage" class="error mat-error">{{errorMessage}}</p>

            <mat-form-field class="login-input"> 
                <input matInput type="text"
                        id="emailId"  
                        formControlName="email"
                        placeholder="Email"
                        value=""
                        data-test="emailTxt">
                <mat-error *ngIf="loginForm.hasError('required', ['email'])" data-test="requiredEmail">Required</mat-error>
                <mat-error *ngIf="loginForm.hasError('pattern', ['email'])" data-test="validEmail">Provide a valid e-mail address</mat-error>
            </mat-form-field>
            <mat-form-field class="login-input">
                <input matInput type="password" 
                    id="passwordId"
                    formControlName="password"
                    placeholder="Password" 
                    value=""
                    data-test="passwordTxt">
                <mat-error *ngIf="loginForm.hasError('required', ['password'])" data-test="requiredPassword">Required</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" data-test="loginBtn">Log in</button>
        </form>

        <a routerLink="/reset" mat-button data-test="forgotPassBtn">Forgot password?</a>

    </mat-card>
</div>
