import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SetOAuthToken } from '@api/interactors';
import { Authenticate } from '@api/interactors/oauth/Authenticate';
import { PrincipalService } from '@core/services/principal.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private authenticate: Authenticate,
    private setOAuthToken: SetOAuthToken,
    private principal: PrincipalService
  ) {}

  oauth(email: string, password: string): Observable<void> {
    return this.authenticate.execute({username: email, password}).pipe(
      map(oauth => this.principal.login(oauth))
    );
  }
}
