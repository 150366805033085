<div class="reset-page" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="reset-card" >
        <h3 class="mat-title">Forgot password?</h3>
        <p *ngIf="success" class="msg success">An e-mail will be sent to you with password reset instructions</p>
        <form *ngIf="!success" class="reset-form" (ngSubmit)="onSubmit()" [formGroup]="resetForm"> 
            <p *ngIf="errorMessage" class="msg mat-error">{{errorMessage}}</p>
            <mat-form-field class="reset-input"> 
                <input matInput type="text"
                        id="email"  
                        formControlName="email"
                        placeholder="Email"
                        value=""
                        data-test="emailTxt">
                <mat-error *ngIf="resetForm.hasError('required', ['email'])">Required</mat-error>
                <mat-error *ngIf="resetForm.hasError('pattern', ['email'])">Provide a valid e-mail address</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" data-test="initResetBtn">Send verification mail</button>
        </form>
        <a routerLink="/login" mat-button data-test="backBtn">Back to login</a>
    </mat-card>
</div>
