import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UseCase } from '../interactors/UseCase';
import { Resource } from '../models';
import { Http } from '../models/http';
import { CreateRequest } from './CreateRequest';
import { GetLink } from './GetLink';
import { ReplaceParams } from './ReplaceParams';

export namespace GetResourceWithParams {
  export interface Params<L extends string> {
    parent: Resource<L>;
    rel: L;
    queryParams?: object;
  }
}

export class GetResourceWithParams<L extends string, T>
  implements UseCase<GetResourceWithParams.Params<L>, Observable<T>> {
    private getLink = new GetLink<L>();
    private createRequest = new CreateRequest();
    private replaceParams = new ReplaceParams<string>();

  constructor(private http: Http) {}

  execute({parent, rel, queryParams}: GetResourceWithParams.Params<L>): Observable<T> {
    let link = this.getLink.execute({ parent, rel }).href;
    if (link) {
      if (queryParams) {
        link = this.replaceParams.execute({ link, queryParams });
      }

      return this.http.request<void, T>(this.createRequest.execute({
        method: 'GET',
        url: link
      })).pipe(map(response => response.body));
    } else {
      return throwError(new Error(`${rel} link does not exist`));
    }
  }
}
