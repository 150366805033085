import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';
import { catchError, map } from 'rxjs/operators';

import { DebounceUseCase } from '../interactors/DebounceUseCase';
import { Response } from '../models';

export namespace CacheableResource {
  export interface Params {
    clearCache?: boolean;
    forceClear?: boolean;
  }
}

let CACHE: Map<Function, any>; // tslint:disable-line:ban-types no-any

function initCache(force?: boolean) {
  if (!CACHE || force) {
    CACHE = new Map<Function, any>(); // tslint:disable-line:ban-types no-any
  }
}

export abstract class CacheableResource<P extends CacheableResource.Params, T>
    extends DebounceUseCase<P|undefined, T> {
  static clearCache() {
    if (CACHE && this !== CacheableResource) {
      CACHE.delete(this);
    } else {
      initCache(true);
    }
  }

  static updateCache(resource: any) { // tslint:disable-line:no-any
    initCache();
    if (this !== CacheableResource) {
      CACHE.set(this, resource);
    }
  }

  execute(params?: P): Observable<T> {
    return super.execute(params);
  }

  protected debounceExecute(params?: P): Observable<T> {
    initCache();
    const ctor = this.constructor;

    if (!CACHE.has(ctor) || (params && (params.clearCache || params.forceClear))) {
      const cached = CACHE.get(ctor);
      CACHE.delete(ctor);

      return this.doExecute(params).pipe(
        map(resource => {
          CACHE.set(ctor, resource);
          return resource;
        }),
        catchError((error: Response<any>) => { // tslint:disable-line:no-any
          if (cached && error && error.status === 0 && (!params || !params.forceClear)) {
            CACHE.set(ctor, cached);
            return Observable.of(<T>cached);
          } else {
            return throwError(error);
          }
        })
      );
    } else {
      return Observable.of(<T>(<T>CACHE.get(ctor)));
    }
  }

  protected abstract doExecute(params?: P): Observable<T>;
}
