import { Observable } from 'rxjs';
import { UseCase } from '../interactors/UseCase';
import { Resource } from '../models';
import { Http } from '../models/http';
import { LocationStringMap } from '../util';
import { RequestResource } from './RequestResource';

export namespace PutResource {
  export interface Params<L extends string, T> {
    parent: Resource<L>;
    rel: L;
    body?: T;
    noLinkError?: boolean;
    search?: LocationStringMap;
  }
}

export class PutResource<L extends string, T, V>
    implements UseCase<PutResource.Params<L, T>, Observable<V|undefined>> {
  private requestResource: RequestResource<L, T, V>;

  constructor(http: Http) {
    this.requestResource = new RequestResource<L, T, V>(http);
  }

  execute(params: PutResource.Params<L, T>): Observable<V|undefined> {
    return this.requestResource.execute({
      method: 'PUT',
      parent: params.parent,
      rel: params.rel,
      body: params.body,
      noLinkError: params.noLinkError,
      search: params.search
    });
  }
}
