import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';

import { GetOAuthToken, RefreshOAuthToken } from '@api/interactors';
import { OAuthToken } from '@api/models';

@Injectable({
  providedIn: 'root'
})
export class IdentityTokenService {
  constructor(
    private refreshOAuthToken: RefreshOAuthToken,
    private getOAuthToken: GetOAuthToken
  ) { }

  refreshAccessToken(errorResponse: HttpErrorResponse): Observable<OAuthToken> {
    if (errorResponse.status === 401 && errorResponse.error.error === 'invalid_token') {
      const refreshToken = this.getOAuthToken.execute().refresh_token;

      if (this.isRefreshTokenExpired(refreshToken)) {
        return throwError(errorResponse);
      }

      return this.refreshOAuthToken.execute({ refreshToken });
    }

    return throwError(errorResponse);
  }

  isRefreshTokenExpired(token) {
    return decode(token).exp < Math.floor(Date.now() / 1000);
  }
}
