import { environment } from '@env/environment';
import { GetOAuthToken } from '../interactors/oauth/GetOAuthToken';
import { UseCase } from '../interactors/UseCase';
import { Request } from '../models/http';
import { createURLParser } from '../util';

export class CreateRequest<T> implements UseCase<Request<T>, Request<T>> {
  private getOAuthToken = new GetOAuthToken();

  // tslint:disable-next-line:no-shadowed-variable
  execute<T>(request: Request<T>): Request<T> {
    const responseRequest = Object.assign({}, request);

    if (request.search) {
      const parser = createURLParser(request.url, environment.apiBase() || window.location);
      parser.search = '';
      responseRequest.url = parser.href;
    }

    responseRequest.method = request.method || 'GET';
    responseRequest.headers = request.headers || {};
    responseRequest.responseType = request.responseType || 'json';
    responseRequest.headers['Content-Type'] =
      responseRequest.headers['Content-Type'] || 'application/json';

    const token = this.getOAuthToken.execute();
    if (token && token.access_token) {
      responseRequest.headers.Authorization = `Bearer ${token.access_token}`;
    }

    return responseRequest;
  }
}
