import { UseCase } from '../interactors/UseCase';

export namespace ReplaceParams {
  export interface Params<L extends string> {
    link: L;
    queryParams: object;
  }
}

export class ReplaceParams<L extends string>
  implements UseCase<ReplaceParams.Params<L>, string> {
  execute({ link, queryParams }: ReplaceParams.Params<L>): string {
    for (const key in queryParams) {
      if (queryParams[key] !== undefined && queryParams[key] !== null && queryParams[key].toString()) {
        link = <L>link.replace(`{${key}}`, queryParams[key]);
      }
    }

    const emptyParams = link.match(new RegExp(/([^?=&]+)(={([^&]*)})/g));
    if (emptyParams) {
      emptyParams.forEach((emptyParam) => {
        link = <L>link.replace(`${emptyParam}`, '');
      });
    }

    link = <L>link
      .replace(/\?&/, '?')
      .replace(/\?$/, '')
      .replace(/&$/, '');

    return link;
  }
}
