
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';

import { UseCase } from '../interactors/UseCase';
import { RequestMethod, Resource } from '../models';
import { Http } from '../models/http';
import { LocationStringMap, combineLocationString } from '../util';
import { CreateRequest } from './CreateRequest';
import { GetLink } from './GetLink';

export namespace RequestResource {
  export interface Params<L extends string, T> {
    parent: Resource<L>;
    rel: L;
    method: RequestMethod;
    body?: T;
    search?: LocationStringMap;
    noLinkError?: boolean;
  }
}

export class RequestResource<L extends string, T, V>
    implements UseCase<RequestResource.Params<L, T>, Observable<V|undefined>> {
  private getLink = new GetLink<L>();
  private createRequest = new CreateRequest();

  constructor(private http: Http) {}

  execute(params: RequestResource.Params<L, T>): Observable<V|undefined> {
    const { parent, rel } = params;
    const link = this.getLink.execute({ parent, rel });
    if (link) {
      return this.http.request<T, V>(this.createRequest.execute({
        method: params.method,
        url: link.href,
        body: params.body,
        search: params.search && `${combineLocationString(params.search)}`
      })).pipe(map(response => response.body));
    } else if (params.noLinkError) {
      return of(undefined);
    } else {
      return observableThrowError(new Error(`${rel} link does not exist`));
    }
  }
}
