import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { UseCase } from '@api/interactors/UseCase';
import { Api } from '@api/models';
import { GetApi } from '@api/resources';
import { PostResource } from '@api/resources/PostResource';
import { PFCHttpService } from '@api/services';

export namespace FinishReset {
  export interface Params {
    newPassword: string;
    key: string;
  }
}

@Injectable()
export class FinishReset implements UseCase<FinishReset.Params, Observable<void>> {
  private getApi: GetApi;
  private postResource: PostResource<Api.Rel, FinishReset.Params, void>;

  constructor(private http: PFCHttpService) {
    this.getApi = new GetApi(http);
    this.postResource = new PostResource<Api.Rel, FinishReset.Params, void>(http);
  }

  execute({ newPassword, key }: FinishReset.Params): Observable<void> {
    return this.getApi.execute().pipe(
      flatMap(api => {
        return this.postResource.execute({
          parent: api,
          rel: 'reset-finish',
          body: { newPassword, key }
        });
      })
    );
  }
}
