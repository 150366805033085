import { UseCase } from '../UseCase';

export namespace StorageSet {
  export interface Params<T> {
    key: string;
    value?: T;
  }
}

export class StorageSet<T> implements UseCase<StorageSet.Params<T>, void> {
  execute({key, value}: StorageSet.Params<T>): void {
    if (value === undefined) {
      localStorage.removeItem(`pfc.${key}`);
    } else {
      localStorage.setItem(`pfc.${key}`, JSON.stringify(value));
    }
  }
}
