import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class LoggedInInterceptor implements HttpInterceptor {

  constructor(
    private router: Router) { }

    intercept(request: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Request>> {
      return next.handle(request).pipe(catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 401 && errorResponse.error.error === 'Unauthorized') {
          this.router.navigate(['/login']);
          return observableThrowError(errorResponse);
        } else {
          return next.handle(request);
        }
      }
    ));
  }
}
