import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinishReset } from '../interactors/FinishReset';
import { InitReset } from '../interactors/InitReset';

@Injectable()
export class PasswordService {
  constructor(
    private initReset: InitReset,
    private finishReset: FinishReset
  ) {}

  resetPassword(email: string): Observable<boolean> {
    return this.initReset.execute({email}).pipe(
      map(() => {
        return true;
      })
    );
  }

  setNewPassword(newPassword: string, key: string): Observable<boolean> {
    return this.finishReset.execute({newPassword, key}).pipe(
      map(() => {
        return true;
      })
    );
  }
}
