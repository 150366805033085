import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { GetOAuthToken, SetOAuthToken } from '@api/interactors';
import { IdentityTokenService } from '@core/services/identity-token.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private setOAuthToken: SetOAuthToken,
    private getOAuthToken: GetOAuthToken,
    private identityToken: IdentityTokenService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Request>> {
    return next.handle(request).pipe(catchError((errorResponse: HttpErrorResponse) => {
      return this.identityToken.refreshAccessToken(errorResponse).pipe(
        mergeMap(oauth => {
          this.setOAuthToken.execute(oauth);
          const clonedReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.getOAuthToken.execute().access_token}`
            }
          });

          return next.handle(clonedReq);
        }),

        catchError(error => {
          if (error.error && error.error.error === 'invalid_token') {
            this.router.navigate(['/login']);
          }

          return throwError(error);
        })
      );
    }));
  }
}
