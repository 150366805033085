import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Api, OAuthToken } from '../../models';
import { GetApi } from '../../resources/api/GetApi';
import { CreateRequest } from '../../resources/CreateRequest';
import { GetLink } from '../../resources/GetLink';
import { PFCHttpService } from '../../services';
import { combineLocationString } from '../../util';
import { UseCase } from '../UseCase';

export namespace RefreshOAuth {
  export interface Params {
    refreshToken: string;
  }
}

@Injectable()
export class RefreshOAuthToken implements UseCase<RefreshOAuth.Params, Observable<OAuthToken>> {
  private getApi: GetApi;
  private createRequest: CreateRequest<OAuthToken>;
  private getLink = new GetLink<Api.Rel>();

  constructor(private http: PFCHttpService) {
    this.getApi = new GetApi(http);
    this.createRequest = new CreateRequest();
  }

  execute({refreshToken}: RefreshOAuth.Params): Observable<OAuthToken> {
    return this.getApi.execute().pipe(
      flatMap(api => {
        const link = this.getLink.execute({ parent: api, rel: 'authenticate' });
        const request = this.createRequest.execute({
          url: link.href,
          method: 'POST',
          search: combineLocationString(
            { grant_type: 'refresh_token', client_id: 'pfcapp', refresh_token: refreshToken }
          ),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        return this.http.request<OAuthToken>(request).pipe(
          map(response => response.body)
        );
      })
    );
  }
}
