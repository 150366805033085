import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UseCase } from './UseCase';

let REQUEST_MAPS: Map<Function, Map<string, Observable<any>>>; // tslint:disable-line

export abstract class DebounceUseCase<P, R> implements UseCase<P, Observable<R>> {
  private requestMap: Map<string, Observable<R>>;

  constructor() {
    const ctor = this.constructor;
    if (!REQUEST_MAPS) {
      // Lazily create cache incase we need to wait for Map polyfill
      REQUEST_MAPS = new Map<Function, Map<string, Observable<any>>>(); // tslint:disable-line
    }

    if (!REQUEST_MAPS.has(ctor)) {
      REQUEST_MAPS.set(ctor, new Map());
    }

    this.requestMap = <Map<string, Observable<R>>>REQUEST_MAPS.get(ctor);
  }

  execute(params: P): Observable<R> {
    let key: string;
    try {
      key = JSON.stringify(params) || 'default';
    } catch (err) {
      key = 'default';
    }

    if (!this.requestMap.has(key)) {
      this.requestMap.set(key, this.debounceExecute(params).pipe(
        map(result => {
          this.requestMap.delete(key);
          return result;
        }),
        catchError(error => { // tslint:disable-line:no-any
          this.requestMap.delete(key);
          return throwError(error);
        })
      ));
    }

    return <Observable<R>>this.requestMap.get(key);
  }

  protected abstract debounceExecute(params: P): Observable<R>;
}
