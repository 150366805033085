import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Authenticate, GetOAuthToken, RefreshOAuthToken, SetOAuthToken } from '@api/interactors';
import { ResetComponent } from '@pages/account/forgot-password/step1/reset.component';
import { FinishComponent } from '@pages/account/forgot-password/step2/finish.component';
import { FinishReset } from '@pages/account/interactors/FinishReset';
import { InitReset } from '@pages/account/interactors/InitReset';
import { LoginComponent } from '@pages/account/login/login.component';
import { AuthenticationService, PasswordService } from '@pages/account/services';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    ResetComponent,
    FinishComponent
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [],
  providers: [
    Authenticate,
    GetOAuthToken,
    SetOAuthToken,
    RefreshOAuthToken,
    InitReset,
    FinishReset,
    AuthenticationService,
    PasswordService
  ]
})
export class AccountModule { }
