import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@core/page-not-found/page-not-found.component';
import { ResetComponent } from '@pages/account/forgot-password/step1/reset.component';
import { FinishComponent } from '@pages/account/forgot-password/step2/finish.component';
import { LoginComponent } from '@pages/account/login/login.component';

const routes: Routes = [
  { path: '', loadChildren: 'app/pages/shell/shell.module#ShellModule' },
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'finish', component: FinishComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    }
  ) ],
  exports: [ RouterModule ]
})
export class CoreRoutingModule { }
