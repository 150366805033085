import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from as fromPromise } from 'rxjs';
import { catchError, filter, flatMap, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Http } from '../models/http';
import { Request, Response } from '../models/http';
import { ErrorResponse } from '../models/http/ErrorResponse';
import { createURLParser } from '../util';

export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

@Injectable()
export class PFCHttpService implements Http {
  constructor(private httpClient: HttpClient) { }

  request<V>(request: Request<{}>): Observable<Response<V>>;
  request<T, V>(request: Request<T>, responseType: ResponseType = 'json'): Observable<Response<V>> {
    return fromPromise(new Promise(resolve => {
      requestAnimationFrame(resolve);
    })).pipe(flatMap(() => {
      return this.httpClient.request(this.toHttpRequest(request)).pipe(
        filter(event => {
          return event instanceof HttpResponse;
        }),
        catchError(error => {
          throw this.toErrorResponse(error);
        })
      ).pipe(
        map((httpResponse: HttpResponse<V>) => this.toResponse(httpResponse))
      );
    }));
  }

  private toHttpRequest<T>(request: Request<T>): HttpRequest<T> {
    const responseType: ResponseType = 'json';

    const parser = createURLParser(request.url, environment.apiBase() || window.location);
    return new HttpRequest<T>(request.method, parser.href, request.body, {
      responseType,
      params: new HttpParams({ fromString: request.search }),
      headers: new HttpHeaders(request.headers),
      withCredentials: request.withCredentials
    });
  }

  private toResponse<V>(httpResponse: HttpResponse<V> | HttpErrorResponse): Response<V> {
    return httpResponse;
  }

  private toErrorResponse<V>(httpResponse: HttpErrorResponse): ErrorResponse<V> {
    return {
      body: httpResponse.error,
      status: httpResponse.status,
      errorKey: httpResponse.headers.get('X-pfc-error'),
      errorMessage: httpResponse.headers.get('X-pfc-error-message')
    };
  }
}
