import { UseCase } from '../interactors/UseCase';
import { Link, Resource } from '../models';

export namespace GetLink {
  export interface Params<L extends string> {
    parent: Resource<L>;
    rel: L;
  }
}

export class GetLink<L extends string> implements UseCase<GetLink.Params<L>, Link<L>|undefined> {
  execute({ parent, rel }: GetLink.Params<L>): Link<L>|undefined {
    return (parent.links || []).find(link => link.rel === rel);
  }
}
