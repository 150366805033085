import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PasswordService } from '@pages/account/services';

@Component({
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  resetForm: FormGroup;
  errorMessage: string;
  success: boolean;

  constructor(
    private fb: FormBuilder,
    private passwordService: PasswordService
  ) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.pattern('[^ @]+@[^ @]+')]
      ]
    });
  }

  onSubmit(): void {
    if (this.resetForm.valid) {
      this.passwordService.resetPassword(this.resetForm.value.email)
        .subscribe(
          () => this.success = true,
          (error) => this.showErrorMessage(error)
        );
    }
  }

  showErrorMessage(error): void {
    if (error.errorKey === 'email_not_registered') {
      this.errorMessage = 'User does not exist';
    } else {
      this.errorMessage = 'Unkown error';
    }
  }
}
