import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { Api, OAuthToken, Parameters } from '../../models';
import { GetApi } from '../../resources/api/GetApi';
import { CreateRequest } from '../../resources/CreateRequest';
import { GetLink } from '../../resources/GetLink';
import { PostResource } from '../../resources/PostResource';
import { PFCHttpService } from '../../services';
import { combineLocationString } from '../../util/combineLocationString';
import { UseCase } from '../UseCase';

export namespace Authenticate {
  export interface Params {
    username: string;
    password: string;
  }
}

@Injectable()
export class Authenticate implements UseCase<Authenticate.Params, Observable<OAuthToken>> {
  private getApi: GetApi;
  private createRequest: CreateRequest<OAuthToken>;
  private getLink = new GetLink<Api.Rel>();

  constructor(private http: PFCHttpService) {
    this.getApi = new GetApi(http);
    this.createRequest = new CreateRequest();
  }

  execute({ username, password }: Authenticate.Params): Observable<OAuthToken> {
    return this.getApi.execute().pipe(
      flatMap(api => {
        const link = this.getLink.execute({ parent: api, rel: 'authenticate' });
        const request = this.createRequest.execute({
          url: link.href,
          method: 'POST',
          search: combineLocationString(
            { username, password, grant_type: 'password', client_id: 'pfcapp' }
          ),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        return this.http.request<OAuthToken>(request).pipe(
          map(response => response.body)
        );
      })
    );
  }
}
