import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
      const newPassword = AC.get('newPassword').value;
      const confirmPassword = AC.get('confirmPassword').value;

      if (newPassword !== confirmPassword) {
        AC.get('confirmPassword').setErrors({matchPassword: true});
      } else {
        return undefined;
      }
  }
}
