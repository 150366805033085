export type URLParser = HTMLAnchorElement;

export interface URLParserBase {
  protocol: string;
  host: string;
  href: string;
}

export function createURLParser(
  href: string, base?: URLParserBase): URLParser {
  const a = document.createElement('a');

  a.href = href;

  if (base) {
    a.protocol = base.protocol;
    a.host = base.host;
  }

  return a;
}
