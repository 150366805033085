import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { ConfirmModalComponent } from '@core/confirm-modal/confirm-modal.component';
import { AuditActionLabelPipe } from '@shared/pipes/auditActionLabel.pipe';
import { ForwardContractTypePipe } from '@shared/pipes/forwardContractType.pipe';
import { OrderStatusPipe } from '@shared/pipes/ordersStatus.pipe';
import { MaterialModule } from './material.module';
import { KeysPipe } from './pipes/keys.pipe';
import { WorkingOrderStatusPipe } from './pipes/workingOrderStatusPipe.pipe';
import { ThousandSeparator } from './pipes/thousandSeparator';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  declarations: [
    ConfirmModalComponent,
    ForwardContractTypePipe,
    AuditActionLabelPipe,
    OrderStatusPipe,
    WorkingOrderStatusPipe,
    KeysPipe,
    ThousandSeparator
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ForwardContractTypePipe,
    AuditActionLabelPipe,
    OrderStatusPipe,
    WorkingOrderStatusPipe,
    KeysPipe,
    ThousandSeparator
  ],
  providers: [],
  entryComponents: [ConfirmModalComponent]
})
export class SharedModule { }
