import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PFCHttpService } from '@api/services';
import { CoreRoutingModule } from '@core/core-routing.module';
import { LoggedInInterceptor } from '@core/interceptors/logged-in.interceptor';
import { RefreshTokenInterceptor } from '@core/interceptors/refresh-token.interceptor';
import { PageNotFoundComponent } from '@core/page-not-found/page-not-found.component';
import { AccountModule } from '@pages/account/account.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    PageNotFoundComponent
  ],
  imports: [
    SharedModule,
    AccountModule,
    RouterModule,
    CoreRoutingModule
  ],
  exports: [ RouterModule, SharedModule ],
  providers: [
    PFCHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggedInInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
