import { Injectable } from '@angular/core';
import { OAuthToken } from '../../models';
import { StorageSet } from '../storage';
import { UseCase } from '../UseCase';

@Injectable()
export class SetOAuthToken implements UseCase<OAuthToken, void> {
  private storageSet = new StorageSet<string>();

  execute(token: OAuthToken) {
    token = token || <OAuthToken>{};

    this.storageSet.execute({ key: 'oauth.access_token', value: token.access_token });
    this.storageSet.execute({ key: 'oauth.refresh_token', value: token.refresh_token });
    this.storageSet.execute({ key: 'oauth.expires_in', value: token.expires_in });
    this.storageSet.execute({ key: 'oauth.token_type', value: token.token_type });
    this.storageSet.execute({ key: 'oauth.scope', value: token.scope });
    this.storageSet.execute({ key: 'oauth.jti', value: token.jti });
  }
}
