import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { PasswordValidation } from '@pages/account/password-validation';
import { PasswordService } from '@pages/account/services';

@Component({
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {
  finishForm: FormGroup;
  resetKey: string;
  success: boolean;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private passwordService: PasswordService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.resetKey = params.key;
  });
  }

  ngOnInit(): void {
    this.finishForm = this.fb.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(4)
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(4)
      ]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  onSubmit() {
    if (this.finishForm.valid) {
      this.passwordService.setNewPassword(this.finishForm.value.newPassword, this.resetKey)
        .subscribe(
          () => this.success = true,
          (error) => this.showErrorMessage(error)
        );
    }
  }

  showErrorMessage(error): void {
    if (error.errorKey === 'invalid_password') {
      this.errorMessage = 'Invalid password provided!';
    } else if (error.errorKey === 'invalid_user') {
      this.errorMessage = 'Invalid user!';
    } else {
      this.errorMessage = 'Unkown error';
    }
  }
}
