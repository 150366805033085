<h1 mat-dialog-title>Are you sure you want to leave?</h1>

<mat-dialog-content class="mat-body">
  <p>You have unsaved changes! If you leave, your changes will be lost.</p>
  <p>Press <b>OK</b> to continue or <b>CANCEL</b> to stay on current page.</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" data-test="cancelBtn">Cancel</button>
  <button mat-button [mat-dialog-close]="true" data-test="confirmBtn">Ok</button>
</mat-dialog-actions>