import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { PrincipalService } from '@core/services';
import { AuthenticationService } from '@pages/account/services';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private principal: PrincipalService
  ) {}

  ngOnInit(): void {
    this.principal.logout();
    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.pattern('[^ @]+@[^ @]+')]
      ],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authenticationService.oauth(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe(
          () => this.router.navigate(['/']),
          (error) => this.showErrorMessage(error)
        );
    }
  }

  showErrorMessage(error): void {
    if (error.body.error === 'invalid_grant') {
      this.errorMessage = 'Invalid email and/or password';
    } else if (error.body.error === 'unauthorized') {
      this.errorMessage = 'Bad credentials. Please contact your administrator.';
    } else {
      this.errorMessage = 'Unkown error';
    }
  }
}
