import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../../models';
import { PFCHttpService } from '../../services';
import { CacheableResource } from '../CacheableResource';

export class GetApi extends CacheableResource<CacheableResource.Params, Api> {
  constructor(private http: PFCHttpService) {
    super();
  }

  protected doExecute(): Observable<Api> {
    return this.http.request<Api>({
      method: 'GET',
      url: '/api'
    }).pipe(map(response => response.body));
  }
}
